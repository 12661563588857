const SColors = {
    Black: '#000000',
    White: '#FFFFFF',
    Purple350: '#6447A5',
    Purple300: '#7458B3',
    Purple200: '#8973B8',
    Purple150: '#CDC7FF',
    Purple100: '#E3DFFF',
    Purple50: '#F0EEFF',
    Gray400: '#6C6C6C',
    Gray350: '#878787',
    Gray300: '#BBBCBC',
    Gray200: '#DDDEDE',
    Gray150: '#EBEBEC',
    Gray100: '#F7F8F8',
    Gray50: '#F9F9F9',
    Blue350: '#009EDA',
    Blue300: '#5ABFE5',
    Blue200: '#D9F4FF'
}

export default SColors
