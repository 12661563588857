import styled, {CreateStyled} from '@emotion/styled'

import {useTheme} from 'emotion-theming'
import SColors from './colors'

const opinionlessComponentColors =
    {
        buttonBg: SColors.Blue300,
        buttonText: '#FFFFFF',
        dropdownBg: '#17191C',
        spinner: '#FFFFFF',
        divider: 'rgba(183, 184, 185, 0.1)',
        inputBg: '#FFFFFF',
        inputBorder: '#DDDEDE',
        inputPlaceholder: '#BBBCBC',
        inputError: '#FE1149',
        inputWarning: '#CC2936',
        inputLabel: SColors.Black,
        inputShadow: '#D4CDFF',
        segmentedControlActiveText: '#FEFEFE',
        segmentedControlInactiveText: '#4A5266',
        segmentedControlTextHover: '#C0C0C0',
        segmentedControlBottomBorder: '#17191C',
        segmentedControlPaddle: '#FEFEFE',
        tabBarBg: '#17191C',
        tabBarActiveText: '#FEFEFE',
        tabBarInactiveText: '#C0C0C0',
    }

export const logoUrl =
    typeof window !== 'undefined' && !window.location.href.includes('lb') ? '/f4gi-logo.png' : '/lbLogo.png'

const STheme =
    {
        breakpoints: [
            '900px', // 0 mobile
            '968px', // 1 tablet
            '1320px', // 2 desktop
        ],
        fontSizes: [
            8, // 0
            10, // 1
            12, // 2
            14, // 3
            16, // 4
            32, // 5
            42, // 6
        ],
        colors: {
            black: SColors.Black,
            white: SColors.White,
            purple350: SColors.Blue350,
            purple300: SColors.Blue350,
            purple200: SColors.Blue300,
            purple150: SColors.Blue200,
            purple100: SColors.Blue200,
            purple50: SColors.Purple50,
            gray400: SColors.Gray400,
            gray350: SColors.Gray350,
            gray300: SColors.Gray300,
            gray200: SColors.Gray200,
            gray150: SColors.Gray150,
            gray100: SColors.Gray100,
            gray50: SColors.Gray50,
            ...opinionlessComponentColors,
        },
        fonts: {
            default: 'IBM Plex Sans',
            header: 'IBM Plex Mono',
        },
        fontWeights: {
            extraLight: 200,
            light: 300,
            regular: 400,
            medium: 600,
            semiBold: 600,
            bold: 600,
            extraBold: 700,
        },
        radii: [
            4, // 0
            6, // 1
            8, // 2
            12, // 3
        ],
        space: [
            0, // 0
            2, // 1
            4, // 2
            8, // 3
            12, // 4
            16, // 5
            24, // 6
            32, // 7
            40, // 8
            48, // 9
            64, // 10
            96, // 11
            128, // 12
            256, // 13
        ],
        shadows: {
            dropdown: '-2px -2px 5px rgba(27, 30, 35, 0.5), 2px 2px 5px rgba(27, 30, 35, 0.5)',
            main: '0px 0px 20px rgba(0, 0, 0, 0.02)',
            languageSwitcher: '0px 0px 20px rgba(0, 0, 0, 0.2)',
            modal: '0px 0px 15px rgba(0, 0, 0, 0.2)',
        },
        buttons: {
            primary: {
                p: 0,
                m: 0,
                border: 'none',
                outline: 'none',
                background: 'none',
                cursor: 'pointer',
            },
        },
    }

export const SStyled = styled as CreateStyled<typeof STheme>
export const useSTheme = () => useTheme<typeof STheme>()

export default STheme
