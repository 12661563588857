import React from 'react'
import { Box, BoxProps } from 'rebass'

type Props = BoxProps

const SBox: React.FC<Props> = React.forwardRef((props: Props, ref) => {
    return <Box ref={ref} {...props} />
})

SBox.displayName = 'SBox'

export default SBox
